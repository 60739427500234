$fa-font-path: "../fonts/fontawesome/";

$primary: #deb77c;
$secondary: #3d414d;

$third: #85adaf;
$yellow: #fad02b;
$green: #8ca23d;
$blue: #41779d;
$brown: #e08f00;
$body-color: #3d414d;
$danger : #ad2727;
$info: #0dcaf0;
$bg-light: #fffaf4;
$bg: #ECE4D6;
$gray: #eee;
$body-bg: $bg-light;
$dm_regular: "DM Serif Display Regular";
$sansation_regular: "Sansation Regular", Arial, sans-serif, "Apple Color Emoji",
                    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$sansation_bold: "Sansation Bold", Arial, sans-serif, "Apple Color Emoji",
                 "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: $sansation_regular;
$font-size-base: 1rem;

$headings-font-family: $dm_regular !default;
// $headings-color: red;


$theme-colors: (
  "primary": $primary,
  "danger": $danger,
  "info": $info,
  "secondary": $secondary,
  "third": $third,
  "yellow": $yellow,
  "green": $green,
  "blue": $blue,
  "brown": $brown,
  "gray":$gray,
);


$grid-gutter-width: 2rem;
