.blog__wrapper{

  .blog__sidebar{
    >div{
      position: sticky;
      top: 100px;
    }
    .plugin-blog{

      padding: 20px;
      background-color: $white;
      border: 1px solid $yellow;
      margin-bottom: 20px;
    }
    a{
      text-decoration: none;
      color: inherit;
      display: flex;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      justify-content: space-between;
      transition: color .3s ease;
      span{
        font-size: 12px;
      }
      &:hover{
        color: $primary;
      }
    }
    .plugin{
      margin-bottom: 2rem;
    }
    .blog-categories{
      li{
        span{
          display: none;
        }
      }
    }
    ul{
      list-style-type: none;
      padding-left: 0;
      &:last-child{
        margin-bottom: 0;
      }
      ul{
        padding-left: 20px;
        list-style-type: disc;
      }
      li{
        &:not(:last-child){
          border-bottom: 1px dashed #eee;
        }
      }

    }
  }
}
.blog-archive{
  .accordion-button{
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
    &::after{
      display: none !important;
    }
    &.collapsed{
      transform: scaleY(-1);
    }
  }
  >li{
    margin-bottom: 10px;
    >a{
      font-weight: bold;
    }
  }
}
.blog__articles{
  margin-bottom: 30px;
  // display: grid;
  // gap: 20px;
  // @include media-breakpoint-up(lg) {
  //   grid-template-columns: repeat(3, 1fr);
  // }
  .blog__post{
    transition: all .6s cubic-bezier(.86,0,.07,1);
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;

    border: 1px solid lighten($third, 30);
    padding: 20px;
    background-color: white;
    height: 100%;
    margin-bottom: 0;
    img{
      transition: box-shadow .6s cubic-bezier(.86,0,.07,1);
    }
    .post__title{
      font-size: 20px;
      margin-bottom: 10px;
      margin-top: 20px;
      color: $third;
      a{
        text-decoration: none;
        color: inherit;
      }
    }
    a{
      color: $third;
    }
    &:hover{
      border-color: rgba($primary, .2);
      h2{
        color: $primary;
      }
    }
  }
}

