.brochure-row{
  display: grid;
  gap :30px;
  margin-bottom: 30px;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include media-breakpoint-only(md) {
    grid-template-columns: repeat(2, 1fr);
  }

}

.brochure{
  background-color: white;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
  text-align: center;
  .brochure-caption{
    padding: 10px;
    margin-top: 10px;
  }
  .brochure-title{
    font-family: $sansation_bold;
    font-size: 20px;
  }

  .brochure-cover{
    display: block;
    padding: 10px;
  }
  .brochure-links{
    display: grid;
    gap: 10px;
    border-top: 1px solid rgba($secondary, .1);
    padding-top: 10px;


    justify-content: center;
    align-items: center;
    a{
      text-decoration: none;
      color: $secondary;
      &:hover{
        color: $primary;
      }
      i{
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
}
