.activity__list{
  display: grid;
  // grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
  }
  a{
    text-decoration: none;
    color: inherit;
  }
}

.activity__item{
  border: 1px solid $dark;
  padding: 10px;
  position: relative;
  margin-bottom: 0;
  figcaption{
    margin-bottom: 10px;
    margin-top: 10px;
  }
  i.icn{
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 10px;
  }
  .top__info{
    position: absolute;
    right: 10px;
    text-align: right;
    top: 30px;
    z-index: 2;
    display: flex;
    flex-direction: column;

    span.top__info--item{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 38px;
      gap: .5rem;
      padding-left: 10px;
      padding-right: 10px;
      i{
        display: inline-block;
        width: 18px;
        height: 18px;
        background-size: cover;

      }
      &+span{
        margin-top: 10px;
      }
      .booking{
        background-image: url(../img/icons/ticket.svg);
      }
      .icon-recommanded{
        background-image: url(../img/icons/like-shapes-yellow.svg);
        width: 24px;
        height: 24px;
      }
      &::before{
        content: "";
        position: absolute;
        top: 0px;
        left: -18px;
        width: 0;
        height: 0;
        border-left: 18px solid transparent;
        border-right: 0px solid transparent;
        border-bottom: 38px solid;
      }
      &.online-booking{
        background-color: $yellow;
        color: $secondary;
        &::before{
          border-bottom-color: $yellow;
        }
      }
      &.is__recommanded{
        background-color: rgba($secondary, .6);
        color: $yellow;
        &::before{
          border-bottom-color: rgba($secondary, .6);
        }
      }
    }
  }
  .list-item__date{
    font-family: $headings-font-family;
    display: block;
    margin-top: 10px;
    color: $primary;
    font-size: 22px;
  }

  .activity__price{
    // position: absolute;
    // bottom: 10px;
    // right: 10px;
    // padding: 5px;
    // background-color:rgba($secondary, .6);
    // color: $yellow;
    color: inherit;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
    font-family: $sansation_bold;
    span{
      font-size: 80%;
    }
  }
  .activity__image{
    display: block;
    position: relative;
  }
  .activity__type{
    font-size: 90%;
  }
  .activity__rating{
    margin-bottom: 10px;
    .fa-star{
      color: $yellow;
    }
  }

  &[data-is-in-wishlist="true"]{
    background-color: rgba($yellow, .1);
    border-color: $yellow;
  }
}
.activity__title{
  font-family :$sansation_bold;
  font-size: 18px;
  display: inline-flex;

  a{
    color: inherit;
    text-decoration: none;
  }
}
.icon-viarhona {
  background-image: url(../img/sprite.png);
  background-position: -57px -99px;
  width: 68px;
  height: 32px;
}

.activity__labels{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.activity__location{
  i.icn{
    background-image: url(../img/icons/location.svg);
  }
}
.activity__wishlist{
  border: 0;
  padding: 0;
  background-color: transparent;
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1;
  gap: 5px;
  span{
    max-width: 195px;
  }
  i{
    margin-right: 5px !important;
    background-repeat: no-repeat;
  }
  i.icon__add{
    background-image: url(../img/icons/heart-add.svg);
  }
  &.remove{
    padding-left: 5px;
    color: $red;

    i{
      font-size: 18px;
      margin-right: 0 !important;
    }
  }
}

.result__count{
  font-size: 18px;
  font-family: $sansation_bold;
  @include media-breakpoint-up(lg) {
    position: absolute;
    left: 10px;
    top: -35px;
  }
  @include media-breakpoint-down(lg) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.result__wrapper{
  display: grid;
  gap: 1rem;
  position: relative;
  @include media-breakpoint-up(lg) {
    grid-template-columns: 300px 50% auto;
  }
  &.list__hidden{
    @include media-breakpoint-up(lg) {
      grid-template-columns: 300px auto;
      .result__list{
        display: none;
      }
    }
  }
  &.map__hidden{
    @include media-breakpoint-up(lg) {
      grid-template-columns: 300px auto;
      .result__map{
        display: none;
      }
    }
  }

  &.filters__hidden{
    @include media-breakpoint-up(lg) {
      grid-template-columns:60% auto;
      .criteria__form{
        display: none;
      }
      .result__list--wrapper{
        padding-left: 10px;
      }
    }
  }
  &.filters__hidden.map__hidden{
    @include media-breakpoint-up(lg) {
      grid-template-columns:1fr;
    }
  }
  @include media-breakpoint-down(lg) {
    .criteria__form{
      padding: 10px;
      .accordion-button{
        padding: 8px;
        &::after{
          right: -8px;
        }
      }
      .criteria__form--title{
        padding: 5px;
      }
    }
    .result__list{
      order: 1;
      margin-bottom: 20px;
    }
    .result__map {
      order: 2;
    }
    .pagination{
      padding-right: 20px;
      padding-right: 20px;
      margin: 0;
    }
    &.filters__hidden{
      .criteria__form{
        display: none;
      }
    }
  }

}

.map__button{
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
.result__map{
  @include media-breakpoint-up(lg) {
    position: sticky;
    height: 100vh;
    top: 74px;
  }
  @include media-breakpoint-down(lg) {
    position: relative;
    height: 400px;
    display: none;
  }
  #id_map{
    height: 100%;
  }
  .search-results__map-search-activate{
    background-color: rgba(white, .7);
    padding: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
  }
}

.result__list--wrapper{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;

  @include media-breakpoint-down(lg) {
    padding: 0 20px;
  }

}

.map_list--toggle{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  gap:.3rem;
  @include media-breakpoint-down(lg) {
    padding-left: 20px;
    padding-right: 20px;
  }

  button{
    background-color: $secondary;
    border: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    height: 30px;
    width: 50px;
  }
  .form-check-label{
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .form-check-input{
    margin-left: 0;
    margin-right: 0.4rem;
  }
  .form-check-input:checked{
    background-color: $secondary;
    border-color: $secondary;
  }
}


.filter__icn{
  display: inline-block;
  height: 16px;
  width: 16px;
  background-size: cover;
  background-repeat: no-repeat;
}
.icon_map{
  background-image: url(../img/icons/map.svg);
}
.icon_list{
  background-image: url(../img/icons/element-3.svg);
}
.icon_filter{
  background-image: url(../img/icons/setting-4.svg);
}

.criteria__form{

  color: white;
  padding-right: 1rem;
  padding-top: 2rem;
  position: relative;
  form, >div{
    position: relative;
    z-index: 1;
  }
  @include media-breakpoint-down(lg) {
    background-color: $secondary;
  }
  @include media-breakpoint-up(lg) {
    position: sticky;
    height: 100vh;
    top: 68px;
    min-height: 970px;
    &::before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $secondary;
      clip-path: polygon(0% 0%,100% 0%,90% 100%,0% 100%);
    }
  }
  .accordion-button{
    letter-spacing: 1px;
    font-size: 15px;
  }
  a{
    color: white;
    text-decoration: none;
  }

  .accordion-button::after{
    margin-right: 10px;
    position: absolute;
    right: 10px;
  }
  .icon__wrap{
    background-color: $yellow;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color:$secondary;
    border-radius: 20px;
    margin-right: 1rem;
  }
  .criteria-ul{
    li{
      margin-bottom: 10px;
    }
  }
  .select2-container{
    min-width: 250px !important;
  }
  .accordion,
  .accordion-item,
  .accordion-button{
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: white;
    box-shadow: none;
  }
  .accordion-button::after{
    filter: invert(1);
  }
  .accordion-body{
    background-color: darken($secondary, 13);
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  ul{
    list-style-type: none;
    &:has(+ ul) {
      margin-bottom: 0;
    }
    li {
      margin-bottom: .5rem;
    }
    label {
      line-height: 1rem;
    }
    input[type="checkbox"],
    input[type="radio"] {
      margin-left: -1.1rem;
    }
  }
}

.dates__wrapper{
  display: grid;
  gap: 1rem;
  input{
    padding: .6rem;
    padding-left: 2rem;
    border:0;
    background-image: url(../img/icons/calendar.svg);
    background-repeat: no-repeat;
    background-position: 5px center;
    font-size: 14px;
  }
}
#id_accommodation_results_list{
  .cover-section{
    height: 40vh;
    min-height: 500px;
    @include media-breakpoint-down(lg) {
      height: 300px;
      min-height: 300px;
      h1{
        color: $yellow;
        margin-bottom: 0;
        position: relative;
        z-index: 99;
      }
    }
  }
  .select2-container--default .select2-results__option--highlighted[aria-selected]{
    background-color: $yellow;
    color: $dark;
  }
  .main-wrap{
    padding-bottom: 0;
  }
}
.criteria__form--title{
  color: $yellow;
  padding: 20px 30px;
  font-size: 30px;
  letter-spacing: 1px;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.icon__filter{
  width: 24px;
  height: 24px;
  background-image: url(../img/icons/setting.svg);
  display: inline-block;
}

.show__hide--filters{
  position: fixed;
  z-index: 9;
}
.result__wrapper{
  .pagination{
    justify-content: flex-end;
    margin-top: 2rem;
    margin-bottom: 3rem;
    li{
      a{
        display: flex;
        padding: .5rem .8rem;
        text-decoration: none;
        border: 1px solid $secondary;
        text-align: center;
        color: inherit;
        transition: all .3s ease;
      }
      &:not(:last-child){
        a{border-right: 0};
      }
      &:hover, &.active{
        a, span{
          background-color: $yellow;
          border: 1px solid $secondary;
        }
      }
    }
  }

}


.bootstrap-datetimepicker-widget{
  border-radius: 0;
  border: 1px solid rgba($secondary, .6);
  margin: 0;
  // all: unset;
  background-color: white;
  color: $secondary;
  max-width: 300px;
  table {
    thead{
      tr{
        height: 40px;
      }
    }
  }
}


.datepicker {
  padding: 0;
  border-radius: 0;
  .datepicker-days{
    display: flex;
    align-items: center;
    justify-content: center;
    table{
      text-align: center;
      font-family: $sansation_bold;
      tbody{
        .disabled{
          opacity: .3;
          pointer-events: none;
        }
        td:not(.disabled) {
          cursor: pointer;
          &:hover, &.active{
            color: $yellow;
            background-color: rgba($secondary, .9);
          }
        }
      }
    }
  }
  .datepicker-months, .datepicker-years{

    text-align: center;
    thead tr{
      background-color: $yellow;
    }
    table tr td span {
      display: block;
      width: 23%;
      height: 54px;
      line-height: 54px;
      float: left;
      margin: 1%;
      cursor: pointer;
      border: 1px solid rgba($secondary, .6);
      &.disabled{
        opacity: .3;
        pointer-events: none;
      }
      &:not(.disabled){
        &:hover{
          background-color: rgba($secondary, .9);
          color: $yellow;
        }
      }
    }
  }
}

.datepicker-days td, .datepicker-days th {
  width: 2.5em;
  height: 2.51em;
  font-size: 1.5rem;
}

.datepicker-dropdown:after {
  border-bottom-color: #08c;
}

.datepicker-days .table-condensed thead tr:nth-child(2), .datepicker-months .table-condensed thead tr:nth-child(2),
.datepicker-years .table-condensed thead tr:nth-child(2), .datepicker-decades .table-condensed thead tr:nth-child(2),
.datepicker-centuries .table-condensed thead tr:nth-child(2) {
  background: $yellow;
  color: $secondary;
}
.datepicker-days .table-condensed thead tr:nth-child(2) th:hover, .datepicker-months .table-condensed thead tr:nth-child(2) th:hover,
.datepicker-years .table-condensed thead tr:nth-child(2) th:hover,.datepicker-decades .table-condensed thead tr:nth-child(2) th:hover,
.datepicker-centuries .table-condensed thead tr:nth-child(2) th:hover {
  background: $yellow;
  color: $secondary;
}

.datepicker-days .table-condensed tfoot, .datepicker-months .table-condensed tfoot, .datepicker-years .table-condensed tfoot,
.datepicker-decades .table-condensed tfoot, .datepicker-centuries .table-condensed tfoot {
  border-top: solid 1px rgba(0,0,0,.15);
}


.loader-indicator{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100Vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($secondary, .8);
  z-index: 99999999;
  img{
    width: 100px;
  }
}



.documents-list{
  a{
    text-decoration: none;
    margin-bottom: 10px;
    display: flex;
    position: relative;
    align-items: center;
    &::before{
      margin-right: 10px;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 6 Free"; font-weight: 900; content: "\f019";
    }
  }
}
