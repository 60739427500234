#footer{
  background-color: $bg;
  padding-top: 40px;
  @include media-breakpoint-down(lg) {

    .row{
      gap: 20px;
    }
  }
  .title{
    text-transform: uppercase;
    font-family: $sansation_bold;
    font-size: 18px;
    margin-bottom: 10px;
    position: relative;
    &::after{
      content: "";
      width: 70px;
      height: 4px;
      background-color: $primary;
      display: block;
      margin-top: 10px;
    }
  }
  .tab-content{
    border-left: 1px solid $secondary;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: -20px;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
    li{
      display: grid;
      grid-template-columns: 26px auto;
      gap: 20px;
    }
  }
  .nav-pills{
    padding-top: 20px;
    span.nav-link{
      color: $secondary;
      border-radius: 0;
      padding-right: 20px;
      &.active{
        background-color: $secondary;
        color: $yellow;
      }
    }
  }
  .partners-bar {
    background-color: $white;
    margin-top: 3rem;
    padding: 1rem;
    ul{

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      @include media-breakpoint-down(lg) {
        flex-wrap: wrap;
        gap: 10px;
      }
    }
    .icon{
      display: inline-block;
      background-image: url(../img/sprite.png);
    }
  }
  p{
    margin-bottom: .4em;
  }
  a:not(.btn){
    color: inherit;
    text-decoration: none;
    transition: all .3s ease;
    &:hover{
      color: $primary;
    }
  }
  .tab-content{

    .icon{
      display: inline-block;
      width: 24px;
      height: 24px;
      background-size: cover;
      &.icon-mail{
        background-image: url(../img/icons/sms-edit.svg);
      }
      &.icon-location{
        background-image: url(../img/icons/location.svg);
      }
      &.icon-phone{
        background-image: url(../img/icons/call.svg);
      }
      &.icon-mic{
        background-image: url(../img/icons/sms-edit.svg);
      }
      &.icon-clocks{
        background-image: url(../img/icons/calendar.svg);
      }
    }
  }
  .contact-side{
    @include media-breakpoint-down(lg) {
      .d-flex{
        flex-direction: column;
        .nav{
          min-width: 100%;
          margin-right: 0 !important;
          flex-direction: row !important;
          display: grid !important;
          grid-template-columns: repeat(4, 1fr);
          align-items: flex-end;
          font-size: 80%;
          gap: 10px;
          border-bottom: 1px solid $secondary;
          text-align: center;
          span{
            padding: 0 !important;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 14px;
          }
        }
      }
      .tab-content{
        padding: 10px;
        margin: 0;
        border: 1px solid $secondary;
        border-top: 0;
      }
    }
  }
}
.alcohol-notice{
  font-style: italic;
  font-size: 12px;
  opacity: .6;
}



#bottombar{

  padding: 1rem;
  display: flex;
  justify-content: center;
  gap: 20px;
  @include media-breakpoint-down(lg) {
    gap: 10px;
    flex-direction: column;
  }
  a{
    color: $secondary;
    text-decoration: none;
    transition: color .3s ease;
    &:hover{
      color: $primary;
    }
  }
}


//icons
.icon-vgastronomie {
  background-position: 0px 0px;
  width: 106px;
  height: 59px;
}
.icon-viarhona {
  background-position: -57px -99px;
  width: 68px;
  height: 32px;
}
.icon-arche_logo {
  background-position: 0px -99px;
  width: 57px;
  height: 57px;
}
.icon-ardeche_velo {
  background-position: -241px 0px;
  width: 32px;
  height: 40px;
}
.icon-auvergne-rhone-alpes {
  background-position: -106px 0px;
  width: 135px;
  height: 37px;
}
.icon-ladrome {
  background-position: -186px -59px;
  width: 55px;
  height: 32px;
}
.icon-logo-cnr {
  background-position: -106px -37px;
  width: 54px;
  height: 15px;
}
.icon-logo_ardeche_tourism {
  background-position: -95px -59px;
  width: 91px;
  height: 40px;
}
.icon-logodrome {
  background-position: 0px -59px;
  width: 95px;
  height: 40px;
}
.icon-nf {
  background-position: -42px -156px;
  width: 42px;
  height: 39px;
}


.sidr{
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
