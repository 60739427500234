#id_wishlist{
  .wishlist__map{
    @include media-breakpoint-up(lg) {
      top: 126px;
    }
  }
}

#id_wishlist_map {
  height: 100%;
}


.wishlist__wrapper{
  display: grid;
  grid-template-columns: 60% 40%;
  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
  }

}


.wishlist__controls{
  padding: 10px 20px;
  display: flex;
  gap: 10px;
}

.wishlist__list {
  padding: 1rem;
  z-index: 1;
  position: relative;
  background-color: $white;
  border-top: 1px solid #eee;
}

.wishlist__map {
  left: auto;
  bottom: 0px !important;
  right: 0px !important;
}

#id_btn_around_me{
  @include media-breakpoint-only(md) {
    min-width: 60px;
    span{
      display: none;
    }
  }
}
.wishlist__list--ul {

  .list-unstyled{
    padding: 15px 15px;
  }

  .wishlist__item {
    position: relative;
    align-items: center;
    padding: 1em;
    box-shadow: 0 0 20px rgba(0,0,0, .15);
    background-color: white;
    border: 1px solid  darken($bg, 4);
    transition: all .3s ease;
    @include media-breakpoint-up(md) {
      display: grid;
      gap: 10px;
      grid-template-columns: 20px 1fr 130px 40px;
      &.grid__2{
        grid-template-columns: 20px 1fr;
      }
    }
    @include media-breakpoint-down(sm) {
      display: flex;
      gap: 10px;
      padding-top: 30px;
    }
    @include media-breakpoint-only(md) {
      grid-template-columns: 20px 1fr 10px 40px;
    }
    &:hover {
      background-color: darken($bg, 3);
    }
    a{
      text-decoration: none;
    }
    .input__wrapper{
      display: flex;
      input{
        border-right: 0;
        width: 100%;
      }
      button{
        background-color: $secondary;
        color: white;
        min-width: 140px;
      }
    }
  }

  .route-form {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr 220px;
    }
    button{
      border: 0;
      background-color: $yellow;
      padding: 8px;
      transition: all .3s ease;
      &:hover{
        background-color: darken($yellow, 10);
      }
      @include media-breakpoint-down(sm) {
        &[type="submit"]{
          min-width: 100%;
          margin-top: 10px;
        }
      }
      @include media-breakpoint-only(md) {
        &[type="submit"]{
          min-width: 100%;
          margin-top: 10px;
        }
      }
    }
  }
  .route-form-start-address{
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid #eee;
  }

  .route-step {
    .route-direction {
      display: flex;
      padding: .5em;
    }
  }

  .wishlist-item__name {
    font-size: 20px;
    a{
      color: inherit;
    }
  }

  .wishlist-item__locality {
    color: $secondary;
  }

  .wishlist-item__thumbnail {
    flex: 0 1 auto;
    margin-right: 1em;
  }

  .wishlist-item__content {
    flex: 0 1 auto;
  }

  .wishlist-item__sort {
    cursor: move;
    span{
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  .wishlist-item__link {
    display: flex;
    flex: 1 1 auto;
  }

  .wishlist-item__step {
    cursor: move;
  }


  .wishlist-item__remove{
    background-color: $red;
    color: white;
    padding: 10px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include media-breakpoint-down(sm) {
      position: absolute;
      top: 0;
      right: 0;
    }
  }



  .search-wrapper {
    min-height: 700px;
  }

  .search__map {
    background: $gray;
    bottom: 0;
    height: 100%;
    left: auto;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 116px;
    width: 50%;
    z-index: 9999;
  }

  .sortable-ghost {
    .wishlist-item {
      outline: 3px dotted $secondary;
    }
  }
}
.route-calculating-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .75);
  color: white;
  text-align: center;
  display: none;
  color: white;

  span {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  i {
    animation: rotate 2s infinite;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  40% {
    transform: rotate(-45deg);
  }
  60% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(-40deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

