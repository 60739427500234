body .millcheck-widget {
  --var-millcheck-prevnextbg: #{$primary};
  --var-millcheck-available: #{$green};
  --var-millcheck-complete: #{$danger};
  --var-millcheck-fewplace: #{$yellow};
}

.millcheck-widget .millcheck-calendar .heading .btn {
  border-radius: 0 !important;
}
