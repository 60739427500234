
@font-face {
  font-family:"Sansation Regular";
  src:url("../fonts/sansation/Sansation-Regular.woff2") format("woff2"),
  url("../fonts/sansation/Sansation-Regular.otf") format("opentype");
  font-style:normal;font-weight:400;
}


@font-face {
  font-family:"Sansation Bold";
  src:url("../fonts/sansation/Sansation-Bold.woff2") format("woff2"),
  url("../fonts/sansation/Sansation-Bold.otf") format("opentype");
  font-style:normal;font-weight:400;
}

@font-face {
  font-family:"DM Serif Display Regular";
  src:url("../fonts/dm/DMSerifDisplay-Regular.woff2") format("woff2"),
  url("../fonts/dm/DMSerifDisplay-Regular.otf") format("opentype");
  font-style:normal;font-weight:400;
}



h2{
  font-size: 40px;
}
p{
  margin-bottom: 1rem;
  color: $dark;
  &.big_p{
    font-size: 26px;
    font-family: $sansation_bold;
    color: $body-color;
  }
}

