

.ah__header {
  position: relative;
  left: 0px;
  top: 0px;
  z-index: 9999;
  width: 100%;
  transition: all 500ms ease;

  .top_nav {
    display: flex;
    justify-content: flex-end;
    background-color: $bg-light;
    padding-right: 20px;
    .dropdown-toggle{
      display: flex;
      align-items: center;
    }
    .nav {
      gap: 2rem;
      align-items: center;
      a {
        color: inherit;
        text-decoration: none;
      }
      .ticketing {
        background-color: $primary;
        padding: 10px 25px;
        display: flex;
        a{
          display: inline-flex;
          align-items: center;
          gap: 1rem;
        }

      }
      //TODO
      .Resume {
        display: none;
      }
    }
    .icon-ticketing {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-image: url(../img/icons/ticket.svg);
    }
    .language{
      .dropdown-toggle{
        display: flex;
        align-items: center;
        gap: 4px;
        span{
          text-transform: capitalize;
        }
      }
    }
  }
  .dropdown-menu{
    padding-left: 10px;
    padding-right: 10px;
    min-width: 180px;
  }
  .dropdown-toggle:after{
    background-image: url(../img/icons/arrow-down.svg);
    border: 0;
    content: "";
    width: 16px;
    height: 13px;
    display: inline-block;
    background-size: cover;
  }
  .bottom_nav {
    @include media-breakpoint-up(lg) {
      padding: 25px 20px;
    }
    transition: all .3s ease;
    img{
      transition: all .3s ease;
      width: auto;
      max-width: 100%;
    }
    nav {
      justify-content: space-between;
      align-items: center;
      display: grid;
      grid-template-columns: 200px 1fr auto;
      ul.nav {
        gap: 50px;
        a,
        button {
          color: white;
          text-decoration: none;
          font-size: 18px;
          transition: color .3s ease;
          text-transform: uppercase;
          font-family: $sansation_bold;
          &:hover{
            color: $yellow;
          }
        }
        .selected, .ancestor {
          a{
            color: $yellow;
          }
        }
      }
      .main__nav{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  & + div {
    @include media-breakpoint-up(lg) {
      margin-top: -142px;
    }
  }
  @include media-breakpoint-down(lg) {
    position: fixed;
  }
}
.bottom_nav.sticky-header {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  background: $secondary;
  border-bottom: 1px solid rgba(37,38,56,0.1);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 0;
  transition: all 500ms ease;
  padding: 10px;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
.fixed-header .sticky-header {
  z-index: 999;
  opacity: 1;
  visibility: visible;
  animation-name: fadeInDown;
  animation-duration: 500ms;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}



.floating__links {
  @include media-breakpoint-up(lg) {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: fixed;
    top: 202px;
    z-index: 999999;
    right: 1rem;
    transition: all .3s ease;
    li {
      .btn-search{
        cursor: pointer;
      }
      a, span {
        height: 52px;
        width: 52px;
        border-radius: 27px;
        background-color: $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $body-color;
        text-decoration: none;
        font-weight: bold;
        position: relative;
      }
      span.text {
        display: none;
      }
      .wishlist-count {
        background: $yellow;
        height: 24px;
        width: 24px;
        border-radius: 12px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        position: absolute;
        top: -6px;
        right: -6px;
      }
      .icon {
        display: block;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: cover;
        &.icon-camera {
          background-image: url(../img/icons/video.svg);
        }
        &.icon-ticketing {
          background-image: url(../img/icons/ticket.svg);
        }
        &.icon-wishlist {
          background-image: url(../img/icons/heart.svg);
        }
        &.icon-search {
          background-image: url(../img/icons/search.svg);
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {

  }

}

@include media-breakpoint-down(lg) {
  #id_shortcut{
    // display: none;
  }
}
.scroll-margin {
  .floating__links{
    top: 120px;
  }
}

.flag{
  display: inline-block;
  width: 24px;
  height: 20px;
  &-fr{
    background-image: url(../img/flags/24/fr.png);
  }
  &-en{
    background-image: url(../img/flags/24/en.png);
  }
  &-de{
    background-image: url(../img/flags/24/de.png);
  }
  &-nl{
    background-image: url(../img/flags/24/nl.png);
  }
}




/* ---------------------------
RWD
---------------------------------*/
@include media-breakpoint-down(lg) {
  .ah__header{
    padding: 20px;
    background-image: linear-gradient(180deg, #000000 0%, rgba(0,0,0,0.00) 100%);
    transition: all .3s cubic-bezier(0.39, 0.575, 0.565, 1);
    .top_nav, .bottom_nav nav .main__nav{
      display: none;
    }
    .burger__menu{
      span{
        display: block;
        width: 36px;
        height: 40px;
        background-image: url(../img/burger_menu.svg);
      }
    }
    .bottom_nav nav{
      display: flex;
      justify-content: space-between;
    }
    &.fixed-header{
      background-color: $secondary;
    }
  }
}

.sidr-open{
  overflow: hidden;
}

.sidr{
  background-color: $secondary;
  color: white;
  a{
    color: white;
    text-decoration: none;
    display: block;
    padding: 14px 15px;
    font-family: $sansation_bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    border-bottom: 1px solid lighten($secondary,5);
  }
  .mobile__search{
    display: block !important;
    i{
      font-size: 20px;
      color: $yellow;
    }
  }
  .btn-search{
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .sidr-inner{

    ul{
      margin-bottom: 0;
    }
    .wishlist{
      position: relative;
      a{
        .icon-wishlist{
          background-image: url(../img/icons/heart_2.svg);
          width: 24px;
          height: 24px;
          display: inline-block;
        }
      }
    }
    .wishlist-count{
      position: absolute;
      background-color: $yellow;
      color: $dark;
      font-size: 11px;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      left: 31px;
      top: 8px;
    }
  }
  ul{
    padding-left: 0;
    list-style-type: none;
    flex-direction: column;
    // li:not(:last-child){
    //   border-bottom: 1px solid darken($secondary, 10);
    // }
    // li:not(:first-child){
    //   border-top: 1px solid lighten($secondary, 10);
    // }
  }
  .dropdown-menu{
    position: static !important;
    transform: unset !important;
    border-radius: 0 !important;
    background-color: transparent !important;
  }
  .floating__links{
    padding-left: 0;
  }
}


#sidr-id-language_chooser{
  padding-left: 0;
}
