.row-background {
    padding: 15px;

    h2:first-child,
    h3:first-child,
    h4:first-child{
        margin-top: 0;
    }

    a:not(.btn):hover {
        text-decoration: underline;
    }
}

.column-background {
    padding: 30px;
    margin-bottom: 15px;
    overflow: hidden;
    min-height: 100%;

    p:last-child {
        margin-bottom: 0;
    }
    a:not(.btn){
        color: inherit;
        text-decoration: underline;
        font-weight: bold;
        &:hover {
            text-decoration: underline;
        }
    }
}

.white-background {
    //color: $text-color;
    background-color: $white;
}
.bg-yellow{
    background-color: $yellow;
}

.column-spacer {
    width: 100%;
}

.column-spacer-sm {
    height: 2em;
}

.column-spacer-md {
    height: 4em;
}

.column-spacer-lg {
    height: 8em;
}

.bg-secondary, .bg-blue, .bg-brown{
    color: white;
    p{
        color: inherit;
    }
}
.img-thumbnail-img {

    img {
        max-width: 100%;
    }
}
.img-thumbnail-wrapper{
    position: relative;
    .img-info{
        position: absolute;
        bottom: 0;
        left: 0;
        padding: .5rem 1rem;
        background-color: rgba($dark, .7);
        color: white;
    }
}
