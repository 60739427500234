.banner-wrapper {
  .carousel-item {
    position: relative;
    @include media-breakpoint-up(lg) {
      height: 80vh;
      max-height: 780px;
    }
    .item-bg {
      background-size: cover;
      background-position: bottom;
      position: relative;
      @include media-breakpoint-up(lg) {
        height: 80vh;
        max-height: 780px;
      }
      @include media-breakpoint-down(lg) {
        height: 60vh;
      }
      &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(black, .3);
      }
    }

    .carousel-overlay {
      @include media-breakpoint-up(lg) {
        z-index: 9;
        position: absolute;
        max-width: 50vw;
        &,
        a,
        p,
        h2,
        h1 {
          color: white;
        }
        h2,
        h1 {
          font-size: 3vw;
          line-height: 1.2;
        }
        &.slide-top-right {
          right: 100px;
          top: 200px;
          text-align: right;
        }
        &.slide-top-left {
          left: 100px;
          top: 200px;
        }
        &.slide-center-center {
          left: 50%;
          text-align: center;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        &.slide-center-left {
          left: 200px;
          top: 50%;
          transform: translateY(-50%);
        }
        &.slide-center-right {
          right: 200px;
          top: 50%;
          transform: translateY(-50%);
          text-align: right;
        }
        &.slide-bottom-left {
          left: 100px;
          bottom: 100px;
        }
        &.slide-bottom-right {
          right: 100px;
          bottom: 100px;
          text-align: right;
        }
        &.slide-top-center {
          top: 200px;
          left: 50%;
          text-align: center;
          transform: translateX(-50%);
        }
        &.slide-bottom-center {
          bottom: 100px;
          left: 50%;
          text-align: center;
          transform: translateX(-50%);
        }
      }
      @include media-breakpoint-down(lg) {
        padding: 20px;
        h2{
          font-size: 6vw;
        }
      }
      @include media-breakpoint-only(md) {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50px;
        right: 50px;
        text-align: center;
        max-width: 90%;
        color: white;
        transform: translateY(-50%);
        h2{
          font-size: 36px;
        }
        p{
          color: white;
        }
      }
    }
  }
  .carousel-control-next, .carousel-control-prev{
    width: 60px;
  }
  .carousel-indicators {
    @include media-breakpoint-up(lg) {
      bottom: 80px;
    }
    @include media-breakpoint-only(md) {
      bottom: 0px;
    }
    @include media-breakpoint-down(md) {
      bottom: 160px;
    }
    [data-bs-target] {
      width: 17px;
      height: 19px;
      opacity: 1;
      background-color: transparent;
      margin-left: 6px;
      margin-right: 6px;
      transition: all 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
      background-image: url(../img/indicator.svg);
      background-size: cover;
    }
    .active {
      background-image: url(../img/indicator_active.svg);
    }
  }
  @include media-breakpoint-down(lg) {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 25px 20px -20px;
    .carousel-item{
      height: auto;
    }
  }
}

.thematics-form {
  background-color: white;
  position: relative;
  display: grid;
  @include media-breakpoint-up(lg) {
    gap: 10px;
    padding: 10px 20px;
    max-width: 980px;
    margin-top: -20px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(3, 1fr);
  }
  @include media-breakpoint-down(lg) {
    padding: 20px;
    margin-bottom: 30px;
    gap: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    .ah_select{
      color: $secondary;
    }
  }
  @include media-breakpoint-only(md) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
    >div:nth-child(1){ grid-area: 1 / 1 / 2 / 3; }
    >div:nth-child(2) { grid-area: 1 / 3 / 2 / 5; }
    >div:nth-child(3) { grid-area: 2 / 1 / 3 / 5; }
  }
  .form-group {
    display: grid;
    align-items: center;
    grid-template-columns: 60px 1fr;
    gap: 10px;
    .icon {
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url(../img/icons/square.svg);
      background-repeat: no-repeat;
      background-size: contain;
      i {
        margin-left: -7px;
      }
    }
    .thematic {
      background-image: url(../img/icons/image.svg);
    }
    .time {
      background-image: url(../img/icons/calendar.svg);
    }
    label {
      color: lighten($body-color, 40);
    }
    .select_wrap {
      position: relative;
      select {
        appearance: none;
        border: 0;
        @include media-breakpoint-up(lg) {
          width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
        padding-left: 0;
        font-size: 18px;
        background-color: transparent;
        letter-spacing: 1.4px;
        font-family: $sansation_bold;
      }
      &::after {
        content: "";
        width: 16px;
        height: 16px;
        display: block;
        background-image: url(../img/icons/arrow-down.svg);
        background-size: cover;
        position: absolute;
        pointer-events: none;
        right: 15px;
        top: 0px;
      }
    }
  }
  i {
    display: block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .form__submit {
    position: relative;
    @include media-breakpoint-up(lg) {
      transform: translateY(-30px);
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 6px;
        right: -8px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 0 0px 8px;
        border-color: transparent transparent transparent darken($primary, 40);
      }
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 0;
      text-align: center;
      gap: 10px;
      position: relative;
      width: 100%;
      font-family: $sansation_bold;
      @include media-breakpoint-up(lg) {
        z-index: 1;
        background-image: url(../img/icons/btn.svg);
        background-repeat: no-repeat;
        background-size: contain;
        height: 104px;
        letter-spacing: 1.5px;
        font-size: 22px;

      }
      @include media-breakpoint-down(lg) {
        background-color: $primary;
        padding: 20px;
        margin-bottom: -40px;
      }
      .surprise {
        background-image: url(../img/icons/surprise.svg);
      }
    }
  }

}
section.situation{
  padding-bottom: 40px;
}
.situation, .pages_links {
  overflow: hidden;
  position: relative;
  &::before {
    content: "";
    height: 500px;
    width: 140px;
    background-size: contain;
    background-image: url(../img/i_ah.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: -40px;
    top: 50%;
    transform: translateY(-50%);
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}
.situation_wrap {
  display: grid;
  gap: 20px;
  align-items: center;
  @include media-breakpoint-up(lg) {
    grid-template-columns: 40% 1fr;
  }
  @include media-breakpoint-only(md) {
    grid-template-columns: 1fr 1fr;
  }
}
section.section {
  svg {
    &.revert {
      transform: scale(-1);
    }
  }
}
#id_index{

  .main-wrap{
    .container{
      @include media-breakpoint-down(lg) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
  svg {
    width: 100%;
    height: auto;
  }
}

.bg__white {
  background-color: $white;
}
section.must__see {
  .bg__white {
    padding-bottom: 20px;
    padding-top: 40px;
  }
}

#id_index {
  section {
    h2 {
      color: $primary;
      font-size: 48px;
      em {
        font-size: 30px;
      }
    }
  }
}

.zoom__on {
  background-color: white;
  @include media-breakpoint-up(lg) {
    padding-top: 40px;

  }
  h2 {
    text-align: center;
    a {
      text-decoration: none;
    }
  }

}

.carousel-favorites {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  @include media-breakpoint-down(lg) {
    padding: 30px;
    .item{
      position: relative;
    }
  }
  .slick-slide {
    transform: all 0.3s ease-in;
    &:not(.slick-current) {
      position: relative;
      transform: scale(0.7);
      .post__content {
        opacity: 0;
      }
    }
    img{
      min-width: 100%;
    }
  }
  .blog_link {
    margin-top: 20px;
  }
  .slick-arrow{
    &.slick-prev {
      left: 20%;
    }
    &.slick-next {
      right: 20%;

    }
  }
  .post__content {
    padding: 10px;
    background-color: $primary;
    @include media-breakpoint-up(lg) {

      width: 400px;
      transform: translateY(-80%);
      margin-right: auto;
      margin-left: auto;
      h3 {
        font-size: 22px;
      }
    }
    @include media-breakpoint-down(lg) {

      z-index: 1;
      h3{
        font-size: 18px;
      }
    }

  }
  .owl-item {
    &:not(.active) {
      .post__content {
        opacity: 0;
      }
    }
  }
  .slick-dots{
    @include media-breakpoint-down(lg) {
      margin-top: 20px;
    }
  }
}
.carousel-favorites, .must__see{
  .slick-arrow {
    background-image: url(../img/indicator_primary.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    border: 0;
    position: absolute;
    z-index: 99;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 76px;
    width: 68px;
    span {
      display: block;
      width: 24px;
      height: 24px;
    }
    &.slick-prev {
      span {
        background-image: url(../img/icons/arrow-left.svg);

      }
    }
    &.slick-next {
      span {
        background-image: url(../img/icons/arrow-right.svg);
      }
    }
  }
  .slick-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    gap: 10px;
    margin-bottom: 0;
    padding-left: 0;
    button {
      width: 17px;
      height: 19px;
      border: 0;
      text-indent: -9999px;
      opacity: 0.2;
      background-image: url(../img/indicator_primary.svg);
      background-size: cover;
    }
    li.slick-active {
      button {
        opacity: 1;
      }
    }
  }

}
.must__see{
  .slick-arrow{

    &.slick-prev {
      @include media-breakpoint-up(lg) {
        left: -40px;
      }
      @include media-breakpoint-down(lg) {
        left: -15px;
      }
    }
    &.slick-next {
      @include media-breakpoint-up(lg) {
        right: -40px;
      }
      @include media-breakpoint-down(lg) {
        right: -15px;
      }
    }
  }
}
section.zoom{
  padding-bottom: 0;
}
section.agenda {
  display: grid;
  gap: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding-top: 0;
  @include media-breakpoint-up(lg) {
    grid-template-columns: 30% 70%;
  }
  @include media-breakpoint-down(sm) {
    padding-bottom: 20px;
  }
  @include media-breakpoint-only(md) {
    gap: 30px;
    align-items: center;
  }
  &::after {
    content: "";
    height: 510px;
    width: 70px;
    background-size: cover;
    background-image: url(../img/i_ah.svg);
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
  h2 {
    em {
      color: black;
    }
  }
  .highlight {
    background-image: url(../img/bg_yellow.svg);
    padding: 60px 20px;
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    h2 {
      color: white !important;
      line-height: 1;
      margin-bottom: 20px;
    }
  }
  > div {
    @include media-breakpoint-up(lg) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }
  .agenda-block {
    @include media-breakpoint-up(lg) {
      padding-right: 10vw;
      padding-left: 20px;
    }

    @include media-breakpoint-only(md) {
      padding-bottom: 20px;
    }

    .selection-counter, .pagination{
      display: none;
    }
  }
  .img-thumbnail-img {
    background-color: white;
    padding: 10px;
  }
  .agenda-wrapper {
    display: grid;
    margin-top: 60px;
    @include media-breakpoint-up(lg) {
      gap: 10px;
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-down(lg) {
      gap: 20px;
      margin-top: 20px;
      padding: 30px;
    }
    @include media-breakpoint-only(md) {
      margin-top: 20px;
      grid-template-columns: repeat(3, 1fr);
      padding-bottom: 20px;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
    h3.event-title {
      font-family: $sansation_bold;
      text-transform: uppercase;
      font-size: 18px;
    }
    .agenda_item:nth-child(even) {
      @include media-breakpoint-up(lg) {
        margin-top: 100px;

      }
    }
    .event__img{
      @include media-breakpoint-down(lg) {
        border: 1px solid $yellow;
        padding: 10px;
        &::before{
          left: 10px !important;
          right: 10px !important;
          top: 10px !important;
        }
        .event__date{
          top: 20px !important;
          left: 20px !important;
        }
      }
    }
  }

  .agenda_item {
    position: relative;
    .event__img {
      position: relative;
      margin-bottom: 20px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 50%;
        background-image: linear-gradient(
          180deg,
          #2f2f2f 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
      .event__date {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 1;
        color: $yellow;
        font-family: $dm_regular;
        font-size: 48px;
        width: 48px;
        line-height: 1;
        text-align: left;
        text-align: center;
      }
    }
  }
}

section.newsletter {
  background-color: white;
  padding-top: 70px;
  padding-bottom: 70px;
  @include media-breakpoint-down(lg) {
    .container{
      padding: 0 !important;
    }
  }

  figure {
    position: relative;
    .divider{
      width: 78px;
      height: 8px;
      background-color: $white;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    figcaption {
      position: absolute;
      top: 0;
      height: 100%;
      background-color: $primary;
      padding: 20px;
      display: flex;
      align-items: center;
      @include media-breakpoint-up(lg) {
        right: 100px;
        width: 435px;
      }
      @include media-breakpoint-down(lg) {
        left: 20px;
        right: 20px;
      }
      @include media-breakpoint-only(md) {
        right: 360px;
        left: 40px;
      }
      h2 {
        color: $white !important;
        @include media-breakpoint-down(lg) {
          line-height: 1;
          font-size: 24px;
        }
      }
      &::after,
      &::before {
        content: "";
        position: absolute;
        @include media-breakpoint-up(lg) {
          width: 476px;
        }
        @include media-breakpoint-down(lg) {
          width: 100%;
          background-size: contain;
        }
        height: 52px;
        background-image: url(../img/newsletter-bg.svg);
        background-repeat: no-repeat;
      }
      &::before {
        top: -51px;
        right: 0;
        transform: scale(-1);
        @include media-breakpoint-down(lg) {
          right: 21px;
        }
        @include media-breakpoint-only(md) {
          right: 19px;
        }
      }
      &::after {
        bottom: -51px;
        left: 0;
        @include media-breakpoint-down(lg) {
          left: 21px;
        }
        @include media-breakpoint-only(md) {
          left: 19px;
        }
      }
    }
    img{
      max-width: 100%;
      height: auto;
      @include media-breakpoint-down(lg) {
        height: 450px;
        object-fit: cover;
      }
    }
  }
}


.social-links{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 40px;
  a{
    color: inherit;
    font-size: 20px;
    transition: color .3s ease;
    &:hover{
      color: $primary;
    }
  }
}


.cover-section{
  max-height: 600px;
  height: 50vh;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-position: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 94%);
  &::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;bottom: 0;
    background-color: rgba($dark, .7);
  }
  h1{
    position: relative;
    color: $white;
    font-size: 46px;
    font-style: italic;
    max-width: 700px;
    @include media-breakpoint-down(lg) {
      font-size: 36px;
      line-height: 1;
    }
  }
  &.detail__header{
    >div{
      position: relative;
      color: $white;
      h1{
        font-size: 40px;
        color: $yellow;
        margin-bottom: 20px;
        line-height: 1;
      }
    }
    .rating{
      color: $yellow;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
    }
  }
  @include media-breakpoint-down(lg) {
    padding: 20px;
  }
}
.marketing, .pages_links{
  overflow: hidden;
  position: relative;
  &::after{
    content: "";
    position: absolute;
    height: 510px;
    width: 400px;
    top: 14%;
    transform: skewY(4deg);
    right: 0;
    opacity: 0.41;
    background: #DEB77C;
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}

.ads{
  position: relative;
  z-index: 1;
  .ad{
    display: grid;
    align-items: center;
    margin-top: 20px;
    @include media-breakpoint-up(lg) {
      gap: 20px;
      grid-template-columns: 40% 60%;
    }
    a{
      color: inherit;
      display: block;

      text-decoration: none;
    }
  }
  .ad__content{
    padding: 20px;
  }
}

.must__see--slider{
  margin-top: 30px;
  padding-bottom: 50px;
  /* the slides */
  .slick-slide {
    margin: 0 27px;
    @include media-breakpoint-only(md) {
      margin: 0 10px;
    }
  }
  .slick-dots{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
/* the parent */
  .slick-list {
    margin: 0 -27px;
    @include media-breakpoint-only(md) {
      margin: 0 -10px;
    }
  }
  .item{
    display: flex !important;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: inherit;
    position: relative;
    overflow: hidden;
    img{
      min-width: 100%;
      height: auto;
      transition: all .3s ease;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      @include media-breakpoint-only(md) {
        height: 300px;
        object-fit: cover;
        width: 100%;
      }
    }
    h3{
      background-color: $third;
      color: white;
      text-align: center;
      font-family: $sansation_regular;
      padding: 10px 20px;
      font-size: 18px;
      text-align: center;
      transform: rotate(4deg) translateY(-20px);
      transition: all .3s ease;
      margin-left: 20px;
      margin-right: 20px;
      display: inline-block;

      p{
        margin-bottom: 0;
        color: white;
      }
    }
    .description{
      opacity: 0;
      visibility: hidden;
      height: 0;
      overflow: hidden;
      transition: all .3s ease;
      position: absolute;
      bottom: 0;
      transform: translateY(100px);
    }
    &:hover{
      img{
        clip-path: polygon(0 0, 100% 0, 100% 68%, 0 57%);
      }
      h3{
        background-color: $primary;
        color: $body-color;
        transform: rotate(5deg) translateY(-230px) translateX(-20px);
      }
      .description{
        opacity: 1;
        height: 200px;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }

}



.intro{
  font-style: italic;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}



.overview__figure{
  margin-bottom: 60px;
  padding-bottom: 20px;
  padding-top: 20px;
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 30px;
  align-items: center;
  max-width: 80%;
  margin: 30px auto;
  .image__wrapper{
    position: relative;
    span{
      display: block;
      background-color: $third;
      position: absolute;
      height: 100%;
      width: 100%;
      clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
      top: -40px;
      left: -40px;
    }
    img{
      position: relative;
      clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
    }
  }
  figcaption{
    position: relative;
    z-index: 2;
  }
  h3{
    font-style: italic;
    font-size: 40px;
    &::after{
      display: inline-block;
      width: 80px;
      height: 2px;
      background-color: $dark;
      margin-left: 10px;
      content: "";
    }
  }
  .btn{
    margin-top: -20px;
    margin-left: 100px;
  }
  .description{
    font-style: italic;
    padding: 40px;
    background-color: $white;
    position: relative;
    margin-top: 20px;
    &::before{
      content: "";
      position: absolute;
      height: 100%;
      width: 60px;
      left: -60px;
      top: 0;
      background-color: white;
    }
  }
  &.teaser-image-right{
    grid-template-columns: 70% 30%;
    .image__wrapper{
      order: 2;
      span{
        left: unset;
        right: -40px;
        background-color: $yellow;
      }
    }
    figcaption{
      order: 1;
    }
    .description{
      &::before{
        right: -40px;
        left: unset;
      }
    }
  }

}

.menu__pages{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  .overview__figure{
    &:nth-child(odd){
      grid-template-columns: 70% 30%;
      .image__wrapper{
        order: 2;
        span{
          left: unset;
          right: -40px;
        }
      }
      figcaption{
        order: 1;
      }
      .description{
        &::before{
          right: -40px;
          left: unset;
        }
      }
    }
    &:nth-child(1){

    }
    &:nth-child(2){

      .image__wrapper{
        span{
          background-color: $yellow;
        }
      }
    }
    &:nth-child(3){



      .image__wrapper{

        span{
          background-color: $green;
        }
      }
    }
    &:nth-child(4){


      .image__wrapper{
        span{
          background-color: $blue;
        }
      }
    }
    &:nth-child(5){

      .image__wrapper{
        span{
          background-color: $brown;
        }
      }
    }
  }
}

.navigation{
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 20px;
}

.article-intro{
  font-style: italic;
  font-size: 19px;
  padding-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.related__post{
  margin-top: 30px;
  .blog__articles{

    @include media-breakpoint-up(lg) {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}


.pages_links{
  margin-bottom: 40px;
  .pages{
    display: grid;
    position: relative;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 30px;
    margin-bottom: 30px;
    z-index: 1;
    figure{
      text-align: center;
      position: relative;
      a{
        display: block;
        text-decoration: none;
        color: inherit;
      }
      img{
        height: 280px;
        width: 100%;
        object-fit: cover;
      }
      h2{
        font-family: $sansation_regular;
        background-color: $third;
        font-size: 16px;
        padding: 10px;
        color: white;
        transform: rotate(5deg);
        display: inline-block;
        span{}
      }
      .description, .btn{
        display: none;
      }
      figcaption{
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}


.menu__grid{
  display: grid;
  gap: 30px;
  margin-bottom: 20px;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: minmax(auto, 360px);
  }
  @include media-breakpoint-only(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  .menu__item{
    position: relative;
    margin: 0;
    @include media-breakpoint-up(lg) {
      grid-column: span 2;
    }
    overflow: hidden;
    .img__link{
      display: block;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .item__title{
      font-family: $dm_regular;
      font-size: 30px;
      font-style: italic;
    }
    .item-description{
      margin-bottom: 0;
    }
    .link{
      color: $yellow;
      display: block;
    }
    .item-description{
      height: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateY(100px);
      transition: all .5s ease;

    }
    .menu__image{
      position: relative;
      height: 100%;
      background-position: center;
      background-size: cover;
      @include media-breakpoint-down(lg) {
        min-height: 300px;
      }
      &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(179deg, rgba(0,0,0,0.00) 0%, #000000 100%);
        opacity: .3;
      }
    }
    figcaption{

      a{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        padding: 30px;
        z-index: 1;
        color: white;
        text-decoration: none;
        display: flex;
        flex-direction: column-reverse;
        >div{
          border-left: 3px solid transparent;
          transition: all .5s ease;
          padding-left: 30px;
        }
      }
      &::before{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: -webkit-linear-gradient(90deg, rgba(0,0,0,0.8)10%, rgba(0,0,0,0.2) 50%);
        left: 0px;
        bottom: 0px;
        z-index: 1;
        transition: all 500ms ease;
      }
    }
    @include media-breakpoint-up(lg) {
      &:nth-child(-n+2) {
        grid-column: span 3;
      }
      &:nth-child(7) {
        grid-column: span 4;
      }
      &:nth-child(-n+2) {
        grid-column: span 3;
      }

    }
    &:hover{
      figcaption{
        a>div{
          border-color: white;
        }
      }
      .item-description{
        min-height: 100px;
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
#id_activity_details{
  background-color: white;
  header.ah__header{
    background-color: $secondary;
    + div{
      margin-top: 0;
    }
  }
  .cover-section{
    @include media-breakpoint-down(lg) {
      padding: 30px;
    }
  }
  section{
    .list-unstyled{
      li{
        display: flex;
        line-height: 1.5;
        align-items: center;
      }
      i{
        font-size: 12px;
        color: $yellow;
      }
    }
  }
  h2{
    font-size: 30px;

    letter-spacing: 1px;
    margin-bottom: 20px;
    // color: $yellow;
    border-bottom: 5px solid rgba($yellow, .4);
    padding-bottom: 20px;
    position: relative;
    text-transform: uppercase;
    &::before{
      content: "";
      position: absolute;
      height: 20px;
      width: 20px;
      background-image: url(../img/icons/square.svg);
      bottom: -11px;
      left: 0;
      background-size: cover;
    }
  }
  h2, h3, h4, h5{
    font-family: $sansation_bold;
  }
  h3{
    font-size: 22px;
    color: $primary;
  }
  h4{
    font-size: 18px;
  }
  h5{
    font-size: 16px;
  }
  section{
    margin-bottom: 40px;
  }
  .wishlist-buttons{
    display: grid;
  }
}

[data-readmore][aria-expanded='false'] {
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 50px;
    background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 2%, #FFFFFF 100%);
    background-color: transparent;
  }
}


.metting--infos, .facilities, .services-list ul{
  display: grid;
  gap: 10px;
  margin-bottom: 20px;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3,1fr);
  }
  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
  }
}

.metting--infos{
  strong{
    display: block;
  }
}
.contact__side{
  margin-bottom: 30px;
  .share__buttons{
    margin-top: 20px;
    border: 1px solid $bg;
    padding: 20px;
    font-family: $sansation_bold;
    .social-share-buttons{
      margin-top: 10px;
    }
  }
}

.contact-box{
  padding: 40px;
  background-color: $yellow;
  clip-path: polygon(0% 0%,100% 6%,100% 100%,0% 96%);
  @include media-breakpoint-down(sm) {
    margin-top: 30px;
  }
  address{
    font-family: $sansation_bold;
    margin-bottom: 0;
    font-size: 18px;
  }
  li{

    padding: .20px 0;
    display: flex;
    .partner-logo{

      background-color: rgba($dark, .9);
      padding: 10px 20px;
      display: inline-flex;
      align-items: center;
      color: $yellow;
      gap: 10px;
      .icon-recommanded{
        background-image: url(../img/icons/like-shapes.svg);
        width: 24px;
        height: 24px;
        display: inline-block;
      }
    }
  }
  a{
    text-decoration: none;
    display: block;
    color: $dark;
  }
}


.calendar-block-wrapper{
  table{
    min-width: 100%;
  }
  .calendar-wrapper {
    position: relative;
    background-color:$bg-light;

  }
  .calendar-wrapper .calendar-control {
    background-color: #3d414d;
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 10px;
  }
  .calendar-wrapper a.calendar-prev,
  .calendar-wrapper a.calendar-next {
    position: absolute;
    top: 7px;
  }
  .calendar-wrapper a.calendar-prev {
    left: 4px;
  }
  .calendar-wrapper a.calendar-next {
    right: 4px;
  }
  .hide{
    display: none;
  }
  table.month {
    caption-side: top;
    th {
      font-weight: normal;
      font-size: 1em;
      font-family: $sansation_bold;
      text-align: center;
      border-bottom: 1px solid #ddd;
    }
    td {
      width: 39px;
      height: 39px;
      font-size: 1em;
      text-align: center;

      &.past{
        opacity: .3;
      }
    }
    caption {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      color: #3d414d;
      background-color: $yellow;
      padding: 10px;
    }
  }
  td{
    position: relative;
    &::before{
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      border-radius: 5px;
      opacity: .5;
      top: 2px;
      right: 2px;
    }
    &.past{
      &::before{
        display: none;
      }
    }
    &.available{
      &::before{
        background-color: $green;
      }
    }
    &.unavailable{
      &::before{
        background-color: $red;
      }
    }
    &.default{
      &::before{
        background-color: $white;
      }
    }
  }
}
.legend-block{
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  margin-top: 10px;
  .square{
    font-size: 12px;
    &::before{
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-right: 10px;
    }
    &.available-legend{
      &::before{
        background-color: $green;
      }
    }
    &.unavailable-legend{
      &::before{
        background-color: $red;
      }
    }
    &.default-legend{
      &::before{
        background-color: $white;
        border: 1px solid #eee;
      }
    }
  }
}

.social-share-buttons{
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  a{
    width: 40px;
    height: 40px;
    border: 1px solid rgba($primary, .2);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: inherit;
  }
}
.activity__wrapper{
  @include media-breakpoint-up(lg) {
    margin-top: -150px;
  }
}


#id_activity_photos{
  clip-path: polygon(0 0,100% 6%,100% 100%,0 96%);
}

#id_activity_photos{
  position: relative;

  #carousel-activity-photos{
    .owl-dots{
      bottom: 50px;
      left: 0;
      right: 0;
      .owl-dot.active{
        span{
          background-color: $yellow;
        }
      }
    }
    .owl-nav, .owl-dots{
      position: absolute;
      z-index: 1;
    }
    .owl-nav{
      top: 50%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      button{
        background-color: $yellow;
        border: 0;
        padding: 20px;
        color: white;
      }
    }
  }

}


#id_wishlist,
#id_survey,
#id_search{
  .cover-section{
    height: 360px;
  }
}



.accommodation_form{
  @include media-breakpoint-up(lg) {
    form{
      min-width: 700px;
      max-width: 800px;
      margin-top: -60px;
      margin-left: auto;
      margin-right: auto;
      grid-template-columns: 2fr 1fr 80px;

      margin-bottom: 50px;
      position: relative;
      padding: 20px 20px;
      display: grid;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      z-index: 99999;
      &::before{
        content: "";
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        background-color: white;
        clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
      }
      >*{
        position: relative;
        z-index: 1;
      }
      label{
        color: darken(white, 30);
      }

      input[type="text"]{
        border: 0;
        display: block;
      }
      input, select{
        font-weight: bold;
        color: $dark;
      }
      .form-select{
        background-color: transparent;
        border: 0;
      }
      .form-group.d-flex{
        align-items: center;
      }
      .icon {
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(../img/icons/square.svg);
        background-repeat: no-repeat;
        background-size: contain;

        i {
          margin-left: -7px;
          display: block;
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      .search_icon {
        background-image: url(../img/icons/search.svg);
      }
      .date {
        background-image: url(../img/icons/calendar.svg);
      }
      .submit_btn{
        border: 0;
        padding: 0;
        appearance: none;
        background-color: transparent;
        transform: translateY(-20px);
        .icon{
          width: 90px;
          height: 90px;
        }
        span{
          display: none;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    form{
      background-color: white;
      padding: 30px;
      display: grid;
      gap: 20px;
      .submit_btn{
        background-color: $yellow;
        border: 0;
        padding: 10px;
      }
      >.form-group{
        justify-content: space-between;
        .icon{
          display: none;
        }
        >div{
          max-width: 48%;
        }
        input{
          background-image: url(../img/icons/calendar.svg);
          background-position: calc(100% - 5px) 5px;
          background-repeat: no-repeat;
          border: 1px solid #eee;
          padding: 5px;
          width: 100%;

        }
      }
      .form-select{
        background-color: white;
        border-radius: 0;
      }
    }
  }
}
#id_address_map_wrapper{
  position: relative;
  .gps{
    position: absolute;
    right: 10px;top: 10px;
    z-index: 999;
  }
}

#id_address_map{
  height: 400px;
}




.selection__form{
  background-color: white;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
  border: 1px solid darken($bg, 4);
  .form-select{
    background-color: white;
  }
  [for="id_locality_id"]{
    width: 100px;
  }
  .selection-form__submit{
    height: 38px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 6px;
    background-color: $yellow;
    border: 1px solid $yellow;
    margin-left: auto;

  }
}

.OsMoteurConteneur{
  margin-bottom: 20px;
}

.WidgetDirect{
  img{
    max-width: 100%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  }
  .Intitule{
    font-family: $sansation_bold;

    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.3;
    color: $primary;
  }
}
.ListeBillet{
  margin-top: 10px;
  .Billet{
    margin-bottom: 20px;
    border: 1px solid #eee;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  }
  .BtnReserver{
    a{
      display: block;
      text-decoration: none;
      text-align: center;
      background-color: $yellow;
      padding: 10px;
      color: $dark;
    }
  }
  .Prix{
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    border-top: 1px dashed #eee;
    border-bottom: 1px dashed #eee;
    .BlocPrixStd{
      font-family: $sansation_bold;
      font-size: 32px;
      text-align: right;
      color: $primary;
    }
  }
}



.frm__search{
  .wrapper{
    background-color: $white;
    padding: 30px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    p{
      label{
        display: block;
      }
      input{
        display: block;
        padding: 10px;
        border: 1px solid #eee;
        width: 100%;
      }
    }
  }
}




details{
  background-color:$white;
  border: 1px solid darken($bg, 5);
  summary{
    list-style: none;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid lighten($white,4);
    h3{
      margin: 0;
      line-height: 2;
      letter-spacing: 1px;
      font-family: $sansation_bold;
      font-size: 20px;
      color:$secondary;

    }
    svg{
      transition: all .3s ease;
      color: $primary;
    }
    .summary-chevron-up{
      line-height: 0;
    }
  }
  &[open]{
    svg{
      transform: scaleY(-1);
    }
    h3{
      color: $primary;
    }
  }
}
.djangocms_faq_child{
  padding: 10px;
  border-top: 1px solid darken($white,4);
  transition: all .3s ease;
}
.djangocms_faq_container{
  margin-bottom: 40px;
  .djangocms_faq_question{
    margin-bottom: 10px;

  }
}

details summary::marker {
  font-size: 0;
}
.summary-content {
  border-top: 1px solid #e2e8f0;
  cursor: default;
  padding: 10px;
  font-weight: 300;
  line-height: 1.5;
}


.djangocms_faq_question:target{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border: 0;
  margin-bottom: 40px;
  h2{
    color: theme-color('primary');
  }
}

.djangocms_faq_results{
  padding-left: 0;
  li{
    margin-bottom: 10px;
    a{
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
}
.djangocms_faq_keyword, .djangocms_faq_result_keyword{
  position: relative;
  display: inline-block;
  padding: 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
}
.djangocms_faq_result_question{
  font-weight: 700;
}

.faq__search{
  form{

    text-align: center;
    margin-bottom: 20px;
    padding-top: 10px;
    h2{
      color:$primary;

    }
    p{
      color: lighten($secondary, 30);
      font-style: italic;
    }
  }
  .djangocms_faq_results{
    list-style: none;
    padding: 20px;
    border-bottom: 1px solid #eee;
  }
  .djangocms_faq_input{
    min-width: 300px;
    max-width: 100%;
    border-radius: 20px;
    margin-top: 10px;
    padding-left: 25px;
    ::placeholder{
      color: #eee;
    }
  }
}


.room--content{
  display: grid;
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    h5{
      grid-column: 1/-1;
    }
  }
}



#id_landing{
  // .hero{
  //   position: relative;
  //   height: 600px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   flex-direction: column;
  //   overflow: hidden;
  //   h1{
  //     position: relative;
  //     z-index: 1;
  //   }
  //   .img-thumbnail-wrapper{
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     right: 0;
  //     bottom: 0;
  //     img{
  //       min-width: 100%;
  //       height: auto;
  //     }
  //   }
  // }

  .thematic{
    position: relative;
    margin-bottom: 40px;
    img{
      clip-path: polygon(7% 0, 100% 0, 93% 100%, 0% 100%);
    }
    .title{
      background-color: $yellow;
      padding: 40px;
      clip-path: polygon(7% 0, 100% 0, 93% 100%, 0% 100%);
      @include media-breakpoint-up(md) {
        position: absolute;
        top: -30px;
        left: -30px;
      }
      @include media-breakpoint-down(md) {
        margin-top: -30px;
        margin-bottom: 30px;
      }
      h2{
        font-size: 18px;
        font-family: $sansation_regular;
        strong{
          font-size: 36px;
        }
      }
    }
  }
  .offers{
    @include media-breakpoint-up(lg) {
      padding: 40px;
    }
    @include media-breakpoint-down(lg) {
      padding: 20px;
      text-align: center;
    }
    background-color: $yellow;
    margin: 40px 0;
    .offers__wrapper{
      display: grid;
      grid-template-columns: 30% 70%;
      gap: 40px;
      align-items: center;
      @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr;
        gap: 10px;
      }
    }
    .offers__selection{
      display: grid;
      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
      }
      gap: 20px;
      figure{
        background-color: $white;
        display: flex;
        align-items: center;
        text-align: center;
        transition: all .3s ease;
        margin-bottom: 0;
        @include media-breakpoint-only(md) {
          flex-direction: column;
          padding: 10px;
        }
        @include media-breakpoint-down(sm) {
          height: 140px;
        }

        figcaption{
          padding: 20px;
          min-width: 60%;
        }
        img{
          min-width: 40%;
          height: 100%;
          object-fit: cover;
          transition: all .3s ease;
          @include media-breakpoint-only(md) {
            min-width: 100%;
            height: 200px;
          }
        }
        h3{
          margin-bottom: 0;
          font-size: 22px;
        }
        a{
          display: flex;
          align-items: center;
          color: inherit;
          text-decoration: none;
          text-align: center;
          min-width: 100%;
          @include media-breakpoint-only(md) {
            flex-direction: column;
          }
          @include media-breakpoint-down(md) {
            height: 140px;
          }
        }
        &:hover{
          h3{
            color: $primary;
          }
          img{
            padding: 10px;
          }
        }
      }
    }
  }
  .spotlight{
    margin-bottom: 30px;
    figure{
      @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
      }
      @include media-breakpoint-only(md) {
        flex-direction: column;
      }
      img{
        max-width: 100%;
        height: auto;
        @include media-breakpoint-up(lg) {
          width: 50vw;
          max-width: 700px;
        }
        @include media-breakpoint-only(md) {
          min-width: 50vw;
          height: 100%;
          object-fit: cover;
        }
      }
      figcaption{
        background: #f6f5f5;
        box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.2);
        padding: 30px;
        @include media-breakpoint-up(lg) {
          transform: translateX(20px);
        }
        @include media-breakpoint-only(md) {
          margin-top: -40%;
        }
      }
      .btn{
        background-color: $yellow;
      }
    }
  }
  .selection{
    @include media-breakpoint-up(md) {
      padding: 40px;
    }
    @include media-breakpoint-down(md) {
      padding: 20px;
    }
    background-color: $secondary;
    color: white;
    text-align: center;
    h2{
      color: $yellow;
    }
    p{
      color: white;
    }
  }
  .selection__list{
    display: grid;
    gap: 30px;
    @include media-breakpoint-up(lg) {
      margin-top:60px;
      grid-template-columns: repeat(4, 1fr);
    }
    @include media-breakpoint-only(md) {
      grid-template-columns: repeat(2, 1fr);
    }
    .teaser{
      h2, h3, h4{
        color: $secondary;
        font-size: 22px;
        transition: all .3s ease;
      }
      img{
        transition: all .3s ease;
      }
      &:nth-child(even){
        @include media-breakpoint-up(lg) {
          transform: translateY(-30px);
        }
      }
      &:hover{
        h2, h3, h4{
          color: $primary;
        }
        img{
          transform: translateY(-30px);
        }
      }
    }
  }
}



.teaser{
  background-color: white;
  text-decoration: none;
  color: inherit;
  display: block;
  text-align: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px;
  h3{
    font-size: 22px;
    padding: 20px;
  }

}


.page-content{
  .agenda-wrapper{
    display: grid;
    margin-bottom: 20px;
    margin-top: 20px;
    gap: 2rem;
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include media-breakpoint-only(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-only(md) {
      grid-template-columns: repeat(2, 1fr);
    }
    .agenda_item{
      position: relative;
      padding: 10px;
      border: 1px solid $secondary;

      img{
        transition: all .3s ease;
      }
      .event__date{
        font-family: $dm_regular;
        position: absolute;
        top: -20px;
        left: 10px;
        z-index: 1;
        color: $dark;
        font-size: 24px;
        line-height: 1;
        text-align: left;
        background-color: $yellow;
        padding: 10px;
        text-align: center;
        width: 44px;
      }
      .event-wrapper{
        display: block;
        text-decoration: none;
        color: inherit;
        background-color: $secondary;
        position: relative;
      }
      .event-title{
        transition: all .3s ease;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        margin: 0;
        padding: 2rem 1rem;
        color: white;
        font-size: 20px;
        font-family: $sansation_bold;
        background-image: linear-gradient(0deg, #2f2f2f 0, rgba(0, 0, 0, 0) 100%);
      }
      .event-description{
        display: none;
      }
      &:hover{
        img{
          opacity: .2;
        }
        .event-title{
          padding-bottom: 3rem;
          color: $yellow;
        }
      }
    }
  }
}
