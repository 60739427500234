.leaflet-popup-content-wrapper{
  background:$secondary;
  &, a{
    color: white;
  }
  a{
    text-decoration: none;
  }
  h2{
    font-family: $sansation_bold;
    font-size: 16px;
    small{
      color: $yellow;
    }
  }
}
.leaflet-popup-tip{
  background-image: url(../img/tip.svg);
  box-shadow: none;
  background-color: transparent;
  transform: rotate(0);
  width: 48px;
  height: 14px;
  margin: -2px auto 0;
  padding: 0;
}


.marker-cluster-small{
  background-color: #fff;
}
.marker-cluster-small div {
  background-color: #fff;
}
.marker-cluster-medium{
  background-color: #ffe5cf;
}
.marker-cluster-medium div {
  background-color: #ffe5cf;
}
.marker-cluster-large{
  background-color: #ffd8d8;
}
.marker-cluster-large div {
  background-color: #ffd8d8;
}
.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  color: #000;
}
.marker-cluster span {
  line-height: 30px;
}
