.carousel-simple{
  position: relative;
  .carousel-overlay{
    position: absolute;
    padding: 20px;
    left: 0px;
    right: 0;
    bottom: 0;
    text-align: center;
    background-color: rgba($secondary, .8);
    a:not(.btn){
      color: inherit;
    }
    h2, h3{
      color: white;
      font-size: 24px;
      letter-spacing: 1px;

    }
  }
}
