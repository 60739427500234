html {
  /* Sets up the Base 10 stuff */
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}


body {
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}



.main-wrap {
  flex: 1 0 auto;
  @include media-breakpoint-down(sm){
    // padding-top: 100px;
  }
  @include media-breakpoint-up(md){
    padding-bottom: 30px;
  }
  .container-fluid, .container{
    &:last-child{
      margin-bottom: 0;
    }
  }
}

#footer {
  flex-shrink: 0;
}



.breadcrumb{
  margin-top: 10px;
  .breadcrumb-item{
    a{
      text-decoration: none;
      color: $dark;
      &:hover{
        color: $primary;
      }
    }
    & +  .breadcrumb-item{
      position: relative;
      padding-left: 20px;
      &::before{
        position: absolute;
        left: 4px;
        top: 5px;
        content: "";
        background-image: url(../img/icons/arrow-right.svg);
        background-size: 14px;
        background-repeat: no-repeat;
        background-position: center;
        width: 14px;
        height: 14px;
      }
    }
  }
}


.img-thumbnail-wrapper{
  &.img-align-center{
    img{
      display: block;
      margin: 0  auto;
    }
  }
  &.img-align-right {
    img{
      display: block;
      margin-left: auto;
    }
  }
}


.bold{
  font-weight: bold;
}

.bootstrap-datetimepicker-widget.dropdown-menu{
  *{
    font-size: 16px !important;
  }
}



.os-overlay, .OsProduit.api.os-overlay {
  top: 150px;
  z-index: 999999;
}

.OverlayChoixSupplements {
  top: 150px;
  z-index: 1000000 !important;
}

.OsMoteur .OsMoteurCriterisation input,
.OsMoteur .OsMoteurCriterisation select{
  height: auto !important;
  padding: 10px;
  font-size: 12px !important;
  width: 100% !important;
}

.OsMoteurBtnRecherche{
  background-color: $secondary !important;
  border-radius: 0 !important;
  padding: 10px !important;
  font-size: 16px;
  font-weight: normal;
}

.OsMoteurCriterisation{
  >div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .OsMoteurCritere_nbpers{
    grid-column: 1/4;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }
}
#errorpage{
  .cover-section{
    background-color: $secondary;
    height: 150px;
  }
}

.error_wrapper{
  position: relative;
  margin-bottom: 3rem;
  padding-top: 4rem;
  h1{
    text-shadow: 4px 4px 0 rgba($secondary, .3);
    font-size: 80px;
  }
  h2{
    font-family: $sansation_bold;
    font-size: 150px;
    font-weight: 200;
    margin: 0;
    color: $secondary;
    text-transform: uppercase;
    // text-shadow: 4px 4px 0 $primary;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: .1;

  }
  .error_message{

    font-size: 22px;
    font-weight: 400;
    color: #211b19;
    background: $bg-light
    ;
    padding: 10px 5px;
    margin: auto;
    display: inline-block;


  }
}

// .error__code{
//   font-size: 8rem;
//   font-family: $dm_regular;
//   letter-spacing: -40px;
//   span{
//     text-shadow: -8px 0 0 #fff;
//   }
// }




.accordion-button{
  font-size: 22px;
  font-family: $sansation_bold;
}
.accordion-button:not(.collapsed){
  color: $primary;
  background-color: rgba($secondary, .05);
}

.control-group{
  margin-bottom: 20px;
  .control-label{
    font-family: $sansation_bold;
  }
  .controls{
    display: flex;
    margin-top: 10px;
    gap: 30px;
    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
    }
  }
}

.comment-collapse + h3{
  margin-top: 30px;
}


#id_satisfaction_survey_form{
  .btn{
    transform: inherit;
  }
}


#id_survey{
  .cover-section{
    height: 30vh;
    &::before{
      background-color: $secondary;
    }
  }
}


small{
  font-size: 12px;
}


.legal-notices-page{
  .page-content{
    h2{
      font-size: 22px;
    }

  }
  .cover-section{
    height: 400px;
  }
}


.legalnotice-list{
  list-style-type: none;
  padding-left: 0;

  >li{
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid darken($bg, 5);
    h2{
      border-bottom: 1px solid darken($bg, 5);
      padding-bottom: 10px;
    }
  }
}


.button{
  border: 0;
  background-color: $yellow;
  padding: 8px;
  transition: all .3s ease;
  color: inherit;
  text-decoration: none;
  padding: 8px 20px;
  &:hover{
    background-color: darken($yellow, 10);
  }
}



.mobile__search{
  display: none !important;
  @include media-breakpoint-down(sm) {
    display: block ;
  }
}



.pagination{
  .page-link{
    border-radius: 0 !important;
    background-color: $white;
    color: $dark;
    border-color: $dark;
    &:hover{
      background-color: $yellow;
    }
  }
  .page-item.active{
    .page-link{
      background-color: $yellow;
    }
  }
}


[lang="en"],
[lang="de"],
[lang="nl"]{
  .banner-wrapper .carousel-item .carousel-overlay{
    display: none;
  }
}
