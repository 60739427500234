
@keyframes scaleUpDown {
  0% {
    opacity: 1;
    transform: scale3d(1,0,1);
  }
  50% {
    transform: scale3d(1,1,1);
    transform-origin: 50% 0;
    transition-timing-function: ease-out;
  }
  50.1% {
    transform-origin: 50% 100%;
    transition-timing-function: ease-out;
  }
  100% {
    opacity: 1;
    transform: scale3d(1,0,1);
    transform-origin: 50% 100%;
    transition-timing-function: ease-out;
  }
}

#id_results_number{
  @include media-breakpoint-down(lg) {
    padding: 0 20px;
  }
}
.search__form--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.btn--search-close {
  font-size: 2em;
  position: absolute;
  top: 1.25em;
  right: 1.25em;
  display: none;
  z-index: 11;
  border: 0;
  background-color: $primary;
  width: 3rem;
  height: 3rem;
}

.search__form {
  position: relative;
  margin: 5em 0 2em;
}
.search__form-inner {
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.search__input , .search__btn{
  font-family: inherit;
  font-size: 3vw;
  display: inline-block;
  box-sizing: border-box;
  padding: 0.25em;
  text-align: center;
  color: #fff;
  border: none;
}
.btn--search{
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  .icon{
    display: block;
    width: 1.5em;
    height: 1.5em;
    margin: 0 auto;
    fill: white;
  }
}
.search__related{
  color: white;
}

.search__btn{
  background: darken($secondary, 4);
  width: 20%;
  transition: all .6s ease !important;
  .fas{
    font-size: 3vw;
    color:  darken($secondary,10);
  }
  &:hover{
    background-color: $primary;
  }
}

.search__input {
  width: 60%;
  background:  $secondary;
}
.search__info {
  font-size: 90%;
  font-weight: bold;
  position: relative;
  z-index: 9;
  display: block;
  width: 100%;
  padding: 0.85em 0;
  color: $primary;
}

.search__related {
  width: 25%;
  pointer-events: none;
}

.search__suggestion  {
  h3{
    font-size: 1.35em;
    margin: 0;
    &::before{
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: '\21FE';
      display: inline-block;
      padding: 0 0.5em 0 0;
    }

  }
  p{
    font-size: 1.15em;
    line-height: 1.4;
    margin: 0.75em 0 0 0;
  }
}
.btn--search-close {
  opacity: 0;
  transition: opacity 0.4s;
}


.search__suggestion,
.search__info {
  opacity: 0;
  transform: translate3d(0, -50px, 0);
  transition: opacity 0.4s, transform 0.4s;
}
.search--open {
  background-color: darken($secondary, 3);
  opacity: 1;
  visibility: visible;
  .btn--search-close {
    opacity: 1;
  }
  .search__suggestion,
  .search__info {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-delay: 0.4s;
    transition-timing-function: ease-out;
  }
}


.js{
  .main-wrap {
    position: relative;
    transition: transform 0.4s, opacity 0.4s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .main-wrap--move {
    opacity: 0;
    transform: scale3d(0.9,0.9,1);
    .btn--search {
      pointer-events: none;
      opacity: 0;
    }
  }
  .search__form--wrapper {
    position: fixed;
    z-index: 1999999;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    padding: 1rem;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    &::before{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.4);
      opacity: 0;
      transition: opacity 0.4s;
    }
  }
  .btn--search-close {
    display: block;
  }
  .search__form-inner {
    &::after{
      content: '';
      position: absolute;
      top: 0;
      left: 10%;
      width: 80%;
      height: 105%;
      background: $primary;
      opacity: 0;
      transform-origin: 50% 0;

    }
  }
  .search--open {
    pointer-events: auto;
    opacity: 1;
    visibility: visible;
    &::before{
      opacity: 1;
    }
    .search__form-inner::after {
      animation: scaleUpDown 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }
  }
}
.plugin__search{
  margin-bottom: 20px;
}

.search-results {

  ul{
    list-style: none;
    padding-left: 0;
    li{
      padding: 10px;
      h3{
        font-size: 22px;
        font-family: $sansation_bold;
        a{
          text-decoration: none;
          color: inherit;
        }
      }
      .highlighted{
        font-family: $sansation_bold;
        background-color: $yellow;
      }
    }
    li:nth-child(odd){
      background-color: #faf4ec;
    }
  }
}

.pagenav{
  border-top: 1px solid #eee;
  margin-top: 1rem;
  .pagination{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding-left: 0;
    li{
      padding: 1rem;
    }
  }
}
