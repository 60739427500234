/* Date Picker Default Styles */
.ui-datepicker {
  padding: 0;
  margin: 0;
  border-radius: 0;
  background-color: #fff;
  border: 1px solid #dcdcde;
  border-top: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  min-width: 17em;
  width: auto !important;
  z-index: 1000 !important;
}

.ui-datepicker .ui-datepicker-group {
  float: left;
}
.ui-datepicker table {
  font-size: 13px;
  margin: 0;
  border: none;
  border-collapse: collapse;
  width: 100%;
}
.ui-datepicker .ui-widget-header,
.ui-datepicker .ui-datepicker-header {
  background-image: none;
  border: none;
  color: #fff;
  font-weight: normal;
}
.ui-datepicker .ui-datepicker-header .ui-state-hover {
  background: transparent;
  border-color: transparent;
  cursor: pointer;
}
.ui-datepicker .ui-datepicker-title {
  margin: 0;
  padding: 10px 0;
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: relative;
  top: 0;
  height: 34px;
  width: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.ui-datepicker .ui-state-hover.ui-datepicker-prev,
.ui-datepicker .ui-state-hover.ui-datepicker-next {
  border: none;
  text-decoration: none;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-prev-hover {
  left: 0;
}
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-next-hover {
  right: 0;
}
.ui-datepicker .ui-datepicker-prev {
  float: left;
  text-decoration: none;
}
.ui-datepicker .ui-datepicker-prev:before {
  content: "\f053";
}
.ui-datepicker .ui-datepicker-next {
  float: right;
  text-decoration: none;
}
.ui-datepicker .ui-datepicker-next:before {
  content: "\f054";
}
.ui-datepicker .ui-datepicker-next span,
.ui-datepicker .ui-datepicker-prev span {
  display: none;
}
.ui-datepicker .ui-datepicker-next.ui-state-disabled,
.ui-datepicker .ui-datepicker-prev.ui-state-disabled {
  visibility: hidden;
}
.ui-datepicker .ui-datepicker-next:not(.ui-state-disabled):before,
.ui-datepicker .ui-datepicker-prev:not(.ui-state-disabled):before {
  font-family: "Font Awesome 6 Free"; font-weight: 900;
  color: #fff;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 34px;
  height: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.ui-datepicker .ui-datepicker-prev-hover:before,
.ui-datepicker .ui-datepicker-next-hover:before {
  opacity: 0.7;
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 33%;
}
.ui-datepicker thead {
  color: #fff;
  font-weight: 600;
}
.ui-datepicker th {
  padding: 10px;
}
.ui-datepicker td {
  padding: 0;
  border: 1px solid #f0f0f1;
}
.ui-datepicker td.ui-datepicker-other-month {
  border: transparent;
}
.ui-datepicker tr:first-of-type td {
  border-top: 1px solid #f0f0f1;
}
.ui-datepicker td.ui-datepicker-week-end {
  background-color: #f6f7f7;
}
.ui-datepicker td.ui-datepicker-today {
  background-color: #f5e6ab;
}
.ui-datepicker td.ui-datepicker-current-day {
  background: #b8e6bf;
}
.ui-datepicker td.ui-state-disabled {
  cursor: default;
  opacity: 0.2;
}
.ui-datepicker td.ui-state-disabled .ui-state-default {
  background: #dcdcde;
  cursor: default;
}
.ui-datepicker td .ui-state-default {
  background: transparent;
  border: none;
  text-align: center;
  text-decoration: none;
  width: auto;
  display: block;
  padding: 5px 10px;
  font-weight: normal;
  color: #3c434a;
}
.ui-datepicker td .ui-state-default.ui-priority-secondary {
  opacity: 0.3;
}
.ui-datepicker td .ui-state-default.ui-priority-secondary.ui-state-hover {
  opacity: 1;
}
/* WordPress Color Schemes */
/* Default/Unknown */
.ui-datepicker .ui-widget-header,
.ui-datepicker .ui-datepicker-header {
  background: $secondary
}
.ui-datepicker thead {
  background: $primary;
}
.ui-datepicker td .ui-state-hover {
  background: #2271b1;
  color: #fff;
}
/* Fresh */
body.admin-color-fresh .ui-datepicker .ui-widget-header,
body.admin-color-fresh .ui-datepicker .ui-datepicker-header {
  background: #2271b1;
}
body.admin-color-fresh .ui-datepicker thead {
  background: #2c3338;
}
body.admin-color-fresh .ui-datepicker td .ui-state-hover {
  background: #2271b1;
}
/* Blue */
body.admin-color-blue .ui-datepicker .ui-widget-header,
body.admin-color-blue .ui-datepicker .ui-datepicker-header {
  background: #096484;
}
body.admin-color-blue .ui-datepicker thead {
  background: #4796b3;
}
body.admin-color-blue .ui-datepicker td .ui-state-hover {
  background: #096484;
}
/* Coffee */
body.admin-color-coffee .ui-datepicker .ui-widget-header,
body.admin-color-coffee .ui-datepicker .ui-datepicker-header {
  background: #59524c;
}
body.admin-color-coffee .ui-datepicker thead {
  background: #46403c;
}
body.admin-color-coffee .ui-datepicker td .ui-state-hover {
  background: #c7a589;
}
/* Ectoplasm */
body.admin-color-ectoplasm .ui-datepicker .ui-widget-header,
body.admin-color-ectoplasm .ui-datepicker .ui-datepicker-header {
  background: #523f6d;
}
body.admin-color-ectoplasm .ui-datepicker thead {
  background: #413256;
}
body.admin-color-ectoplasm .ui-datepicker td .ui-state-hover {
  background: #a3b745;
}
/* Midnight */
body.admin-color-midnight .ui-datepicker .ui-widget-header,
body.admin-color-midnight .ui-datepicker .ui-datepicker-header {
  background: #363b3f;
}
body.admin-color-midnight .ui-datepicker thead {
  background: #26292c;
}
body.admin-color-midnight .ui-datepicker td .ui-state-hover {
  background: #e14d43;
}
/* Modern */
body.admin-color-modern .ui-datepicker .ui-widget-header,
body.admin-color-modern .ui-datepicker .ui-datepicker-header {
  background: #3858e9;
}
body.admin-color-modern .ui-datepicker thead {
  background: #26292c;
}
body.admin-color-modern .ui-datepicker td .ui-state-hover {
  background: #3858e9;
}
/* Ocean */
body.admin-color-ocean .ui-datepicker .ui-widget-header,
body.admin-color-ocean .ui-datepicker .ui-datepicker-header {
  background: #738e96;
}
body.admin-color-ocean .ui-datepicker thead {
  background: #627c83;
}
body.admin-color-ocean .ui-datepicker td .ui-state-hover {
  background: #9ebaa0;
}
/* Sunrise */
body.admin-color-sunrise .ui-datepicker .ui-widget-header,
body.admin-color-sunrise .ui-datepicker .ui-datepicker-header {
  background: #e65054;
}
body.admin-color-sunrise .ui-datepicker thead {
  background: #b32d2e;
}
body.admin-color-sunrise .ui-datepicker td .ui-state-hover {
  background: #dd823b;
}
/* Light */
body.admin-color-light .ui-datepicker .ui-widget-header,
body.admin-color-light .ui-datepicker .ui-datepicker-header {
  background: #8c8f94;
}
body.admin-color-light .ui-datepicker thead {
  background: #50575e;
}
body.admin-color-light .ui-datepicker td .ui-state-hover {
  background: #04a4cc;
}
/* bbPress Color Schemes */
/* Evergreen */
body.admin-color-bbp-evergreen .ui-datepicker .ui-widget-header,
body.admin-color-bbp-evergreen .ui-datepicker .ui-datepicker-header {
  background: #56b274;
}
body.admin-color-bbp-evergreen .ui-datepicker thead {
  background: #36533f;
}
body.admin-color-bbp-evergreen .ui-datepicker td .ui-state-hover {
  background: #446950;
}
/* Mint */
body.admin-color-bbp-mint .ui-datepicker .ui-widget-header,
body.admin-color-bbp-mint .ui-datepicker .ui-datepicker-header {
  background: #4ca26a;
}
body.admin-color-bbp-mint .ui-datepicker thead {
  background: #4f6d59;
}
body.admin-color-bbp-mint .ui-datepicker td .ui-state-hover {
  background: #5fb37c;
}
/* Parent Input Alignment */
body.wp-admin:not(.rtl) .ui-datepicker {
  margin-left: -1px;
}
body.wp-admin.rtl .ui-datepicker {
  margin-right: -1px;
}

/*# sourceMappingURL=datepicker.css.map */
