.video {
  p {
    color: white;
  }
  position: relative;
}

.cookieoptin-notice + iframe {
  display: none;
}

.cookieoptin-notice:not(.cookieoptin-hide) + iframe,
.cookieoptin-notice:not(.cookieoptin-hide) + .fluid-width-video-wrapper {
  display: none;
}

.cookieoptin-hide {
  display: none;
  &.cookieoptin-notice + iframe {
    display: block;
  }
}

$optin_primary: $primary;
$optin_slider: #2980b9;

.cookieoptin-notice {
  padding: 20px;
  border: 1px solid #fff;
  strong {
    color: white;
    display: block;
  }
  a {
    color: $optin_primary;
  }
  .btn {
    box-shadow: none;
    border-radius: 0;
    padding: 10px 15px;
    border: 0;
    &.btn-success {
      background-color: $optin_primary;
      color: white;
    }
    &.btn-decline {
      border: 1px solid #888;
      color: #444;
      background-color: transparent;
    }
  }
}

#klaro {
  .cookie-modal {
    z-index: 99999;
  }
  .klaro {
    input:checked + .cm-app-label .slider {
      background-color: $optin_slider;
    }
    p,
    h1,
    h2,
    ul,
    li,
    strong {
      color: #333;
    }
    a {
      color: $optin_primary;
    }
    h1 {
      font-size: 24px;
      font-weight: bold;
    }
    /* ---- Btn ----*/
    .cm-btn {
      order: 3;
      box-shadow: none;
      border-radius: 0;
      padding: 10px 35px;
      &.cm-btn-success {
        order: 1;
        background-color: $yellow;
        color: $secondary;
        &:hover {
          background-color: $yellow-300;
        }
      }
      &.cm-btn-info {
        order: 2;
      }
      &.cn-decline {
        border: 1px solid #fff;
        color: $gray-500;
        background-color: $gray-200;
        &:hover {
          border: 1px solid #ccc;
        }
      }
    }
    .cm-modal {
      background-color: #fff;
      .cm-header,
      .cm-footer {
        border-color: #eee;
      }
      .cm-toggle-all {
        border-top: 0;
      }
      .cm-body {
        padding: 30px;
      }
    }

    .cookie-notice {
      color: white;
      padding-top: 120px;
      border: 2px solid $yellow;
      box-shadow: none;
      background-color: $white;
      border-radius: 0;
      @media (min-width: 990px) {
        max-width: 360px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 20px;
        right: 50%;
        margin-right: -50px;
        height: 90px;
        width: 90px;
        background-size: cover;
        background-image: url(../img/cookie.svg);
      }

      .cn-ok {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #eee;
        @include media-breakpoint-down(sm) {
          flex-direction: column;
          .cm-btn:not(:nth-child(even)) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
