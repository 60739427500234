.btn {
  // color: #fff;
  text-align: center;
  text-decoration: none;
  border: 0;
  outline: 0;
  padding: 12px 35px;
  display: inline-block;
  transform: skew(-10deg);
  border-radius: 0;
  transition: all .3s linear;
  position: relative;
  overflow: hidden;
  // border:1px solid ;
  span{
    display: inline-block;
    transform: skew(10deg);
    transition: all .3s linear;
    position: relative;
    z-index: 1;
  }
  &::before, &::after{
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  &::before{
    transition: all .1s linear;

  }
  &::after{
    transition: all .4s linear;
  }

  &.btn-primary{
    &::before{
      background-color: darken($primary, 5);
    }
    &::after{
      background-color: darken($primary, 10);
    }
  }
  &.btn-secondary{
    color: white;
  }
  &:hover{


  }

}

